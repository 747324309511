<template>
    <v-flex xs12 sm12 md8 lg6 xl4>
        <v-card>
            <v-card-title>
                <v-icon left>mdi-map-marker</v-icon>
                    <span class="subtitle-1 font-weight-regular application--card--title">{{ this.tabla.naziv }}</span>
                    <v-spacer></v-spacer>
                <v-btn icon @click="show = !show">
                    <v-icon>{{ show ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                </v-btn>
            </v-card-title>
        </v-card>
        <!--<br v-if="this.tabla.semaforji.length > 0">-->
        <div v-if="this.tabla.semaforji.length > 0" style="min-height:10px"></div>
        <!-- semaforji -->
        <semafor 
        v-for="(semafor, i) in this.tabla.semaforji"
        :key="i"
        :semafor="semafor"
        :tabla="tabla.naziv"
        v-show="show"
        >
        </semafor>

    </v-flex>
</template>

<script>
const Semafor = () => import ('./Semafor.vue')
export default {
    name: 'tabla-mobile',
    props: ['id', 'tabla'],
    components: {
        Semafor
    },

    data: () => ({
        show: true
    }),

    computed: {
        stanja() {
            return this.$store.getters.stanja
        },

        nacini() {
            return this.$store.getters.nacini
        },

        semaforji() {
            return this.tabla.semaforji
        },

        toolbarTitlePaddingTopBottom() {
            if(this.tabla.naziv.length > 39) {
                return true;
            }

            return false;
        }
    },

    methods: {
        naicnDelovanja(nacin) {
            if(nacin) {
                return 'Manual'
            }

            return 'Auto'
        },

        submit() {
            let semaforji = this.tabla.semaforji
            //console.log(semaforji)
        },

        cancel() {
           this.$store.dispatch('TABLA', this.id)
           .then(response => {
               console.log("TABLA success")
               //console.log(response)
           })
           .catch(error => {
               console.log("TABLA error")
               console.log(error)
           })
        }
    },

    created() {

    }
}
</script>

<style scoped>
    #tabla-mobile-title {
        font-size:15px !important;
        margin-left:10px !important;
        /*white-space: normal !important;*/
    }

    #tabla-mobile-toolbar .v-toolbar__content {
        padding-top: 10px;
        padding-bottom: 10px;
    }

</style>


